import React, { useEffect, useState } from "react"
import ArticlePage from "templates/ArticlePage"
const contentful = require("contentful")

const DynamicArticle = ({ pageContext: { commonData }, location }) => {
    const [article, setArticle] = useState(null)

    const contentfulClient = contentful.createClient({
        space: "vm2u8m298xe9",
        accessToken: "KwvnF7b5Omvkj_eBknHxn1gsfI_wm-YEcikP1llF45k",
    })

    useEffect(() => {
        contentfulClient
            .getEntries({
                content_type: "article",
                "fields.slug[in]": "tesla-increases-price",
            })
            .then(entries => {
                const entry = entries.items[0]

                if ("/article/" + entry.fields.slug === location.pathname) {
                    setArticle({
                        headline: entry.fields.headline,
                        slug: entry.fields.slug,
                        articleType: entry.fields.articleType,
                        sortTimestamp: entry.fields.sortTimestamp,
                        companies: entry.fields.companies.map(company => ({
                            shortName: company.fields.shortName,
                            ticker: company.fields.ticker,
                        })),
                        shortDescription: { shortDescription: entry.fields.shortDescription },
                        text: { json: entry.fields.text },
                        seoTitle: entry.fields.seoTitle,
                        seoDescription: entry.fields.seoDescription,
                        ogImage: { file: { url: entry.fields.ogImage.fields.file.url } },
                    })
                }
            })
            .catch(err => console.log(err))
    }, [])

    return <ArticlePage pageContext={{ commonData }} data={{ article }} location={location} />
}

export default DynamicArticle
