import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import CenteredLayout from "layouts/Centered"
import Header from "components/Common/Header"
import PageMetadata from "components/Common/PageMetadata"
import ArticleFull from "components/ArticleFull"
import SocialShare from "components/UI/SocialShare"
import FeaturedArticlesSidebar from "components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import linkResolver from "../utils/linkResolver"
import FloatingMenu from "components/UI/FloatingMenu"
import { useMediaQuery } from "react-responsive"
import t from "theme"
import { CardWrapper } from "components/UI/Card"
const contentful = require("contentful")

export const query = graphql`
    query($slug: String!) {
        article: contentfulArticle(slug: { eq: $slug }) {
            ...ArticleContent
        }
    }
`

const ArticlePage = ({ pageContext: { commonData }, data: { article }, location }) => {
    const featuredArticles =
        article && article.companies
            ? getFeaturedArticles(commonData, article.companies[0].ticker)
            : getFeaturedArticles(commonData)

    const selectedCompany = article && article.companies ? article.companies[0] : null

    const isTabletOrPhone = useMediaQuery({
        query: `(max-width: ${t.breakpoint.large})`,
    })

    const [articleTweetUrl, setArticleTweetUrl] = useState(null)

    const contentfulClient = contentful.createClient({
        space: "vm2u8m298xe9",
        accessToken: "KwvnF7b5Omvkj_eBknHxn1gsfI_wm-YEcikP1llF45k",
    })

    useEffect(() => {
        contentfulClient
            .getEntries({
                content_type: "article",
                "fields.slug[in]": article.slug,
            })
            .then(entries => {
                const entry = entries.items[0]
                console.log(entry.fields.articleTweetUrl)

                if (entry.fields.articleTweetUrl) {
                    setArticleTweetUrl(entry.fields.articleTweetUrl)
                }
            })
            .catch(err => console.log(err))
    }, [])

    return (
        article && (
            <CenteredLayout
                header={
                    <Header
                        commonData={commonData}
                        selectedCompany={selectedCompany}
                        selectedFeature="Articles"
                        pathname={location.pathname}
                    />
                }
                sidebar={<FeaturedArticlesSidebar title="FEATURED ARTICLES" featureds={featuredArticles} />}>
                <PageMetadata pageContent={article} pagePathname={location.pathname} />

                <ArticleFull
                    articleData={article}
                    css={css`
                        margin-bottom: ${t.space[4]};
                    `}
                />
                <SocialShare linkUrl={linkResolver({ slug: article.slug, type: "article" })} />
                {articleTweetUrl && (
                    <a href={articleTweetUrl}>
                        <CardWrapper
                            css={css`
                                margin-top: ${t.space[4]};
                            `}
                            shadow>
                            <i
                                css={css`
                                    font-size: ${t.font.size[12]};
                                    display: block;
                                    text-align: center;
                                    margin-bottom: ${t.space[2]};
                                `}
                                className="icon-twitter"
                            />
                            <div
                                css={css`
                                    color: ${t.color.darktext};
                                    font-weight: ${t.font.weight.semibold};
                                    font-size: ${t.font.size[6]};
                                    letter-spacing: ${t.font.spacing[7]};
                                    text-transform: uppercase;
                                    text-align: center;
                                `}>
                                Discuss on Twitter
                            </div>
                        </CardWrapper>
                    </a>
                )}
                {isTabletOrPhone ? <FloatingMenu linkUrl={location.pathname} /> : null}
            </CenteredLayout>
        )
    )
}

export default ArticlePage
